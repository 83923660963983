import { Collapse, FormGroup, Spinner, SpinnerProps, Tooltip } from 'reactstrap'
import Select, { StylesConfig } from 'react-select'
import { projectStatusObject } from '../../utils/constants'
import ReactQuill from 'react-quill'

//@ts-ignore
import { StickyTable, Row, Cell } from 'react-sticky-table'
import SearchInput from '../DesignSystem/Core/Common/SearchInput/SearchInput'
import SingleDatePickerComponent from '../SingleDatePickerComponent'
import moment from 'moment'
import {
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import axios from 'axios'
import { addImage } from '../../api'
import QButton from '../DesignSystem/Core/Common/QButton/QButton'
import '@fortawesome/fontawesome-free/css/all.min.css'

import { camelCaseToSpaced } from '../../utils/common'
import { Link } from 'react-router-dom'
import {
  AdminRoles,
  UnitType,
  ControlledCollapseMenuItemNEWProps,
  DashComponentCommonPropType,
  OrderStatus,
  PaymentMethodEnum,
  UserType,
  UnitDisplayNames,
} from '../../utils/types'
import { formatNumberWithCommas } from '../../utils/utilities'

export const Dummy = () => {
  return 'Dummy'
}

export const ProjectStatusOptionList = () => (
  <>
    <option value={projectStatusObject.green.key}>
      {projectStatusObject.green.label}
    </option>
    <option value={projectStatusObject.yellow.key}>
      {projectStatusObject.yellow.label}
    </option>
    <option value={projectStatusObject.red.key}>
      {projectStatusObject.red.label}
    </option>
  </>
)
export const IsProjectCompleteOptionsComponent = () => (
  <>
    <option value={'false'}>Not Completed</option>
    <option value={'true'}>Completed</option>
  </>
)

export const ColorBar = ({ color }: { color: string }) => (
  <span className={'color-bar ' + color}></span>
)

export const ChevronUpDown = ({
  sortDirection,
  thisSortBy,
  sortBy,
}: {
  sortDirection: string
  thisSortBy: string
  sortBy: string
}) => {
  return (
    <i
      className={`ml-1 ti-angle-${sortDirection === 'asc' ? 'down' : 'up'} ${sortBy === thisSortBy ? 'activeSort' : ''}`}
    ></i>
  )
}

export const SortableTableHeader = ({
  label,
  property,
  sortBy,
  sortDirection,
  handleSortTableHead,
}: any) => (
  <Cell>
    <span className="sortable" onClick={() => handleSortTableHead(property)}>
      {label}
      <ChevronUpDown
        sortDirection={sortDirection}
        thisSortBy={property}
        sortBy={sortBy}
      />
    </span>
  </Cell>
)

export const TableTopFilters = ({
  state,
  setState,
  getData,
  showDateRange = true,
  handleExportOrders,
}: any) => (
  <div className="row mb-2">
    <div className="col-lg-12">
      <FormGroup className="search-by-wrap">
        <select
          className="form-control"
          value={state.searchType}
          onChange={(e) => {
            setState({ ...state, searchType: e.target.value })
          }}
        >
          <option value="name">Search User by Name</option>
          <option value="email">Search User by Email</option>
        </select>
        <SearchInput
          onClick={getData}
          value={state.searchTerm}
          onChange={(e) => setState({ ...state, searchTerm: e.target.value })}
        />
      </FormGroup>
      <SelectComponent
        placeholder="Account Type"
        onChange={(value) => setState({ ...state, userType: value })}
        options={[
          { value: '', label: 'ALL' },
          ...Object.values(UserType).map((status) => ({
            value: status,
            label: camelCaseToSpaced(status),
          })),
        ]}
      />
    </div>
    {showDateRange && (
      <div className="col-lg-12">
        <div className="d-flex justify-content-start">
          <SingleDatePickerComponent
            setDate={(d) => setState({ ...state, fromDate: d.toDate() })}
            date={state.fromDate ? moment(state.fromDate) : null}
            id="fromDate"
            placeholder="Start Date"
            isBefore={false}
          />
          <SingleDatePickerComponent
            setDate={(d) => setState({ ...state, toDate: d.toDate() })}
            date={state.toDate ? moment(state.toDate) : null}
            id="toDate"
            placeholder="End Date"
            isBefore={false}
          />
          <div className="d-flex align-items-center justify-content-start pl-sm-3 pr-sm-3 mb-sm-1 mt-2">
            <button
              className="btn btn-primary pl-3 pr-3"
              disabled={state.currentPage === 1}
              onClick={() =>
                setState({ ...state, currentPage: state.currentPage - 1 })
              }
            >
              Prev
            </button>
            <p className="current-page-number ml-2 mr-2 mb-0">
              <strong>Page {state.currentPage}</strong>
            </p>
            <button
              className="btn btn-primary pl-3 pr-3"
              disabled={
                state.filteredData ? state.filteredData.length === 0 : true
              }
              onClick={() =>
                setState({ ...state, currentPage: state.currentPage + 1 })
              }
            >
              Next
            </button>
            <QButton
              loading={state.isExportDownloadLoading}
              onClick={handleExportOrders}
              round
              size="sm"
            >
              Download Orders
            </QButton>
          </div>
        </div>
      </div>
    )}
  </div>
)
export const TableTopFiltersNewUI = ({
  state,
  setState,
  getData,
  sortInputs = null,
  showDateRange = true,
  handleExportOrders,
  fieldsConfig = {
    showOrdertype: true,
    showPaymentMethod: true,
    showOrderStatus: true,
    showAccountType: true,
  },
}: any) => {
  const { showOrdertype, showPaymentMethod, showOrderStatus, showAccountType } =
    fieldsConfig
  return (
    <div className="tr-filter-wrapper">
      <div className="row">
        {showOrdertype && (
          <div className="col-lg-3 mb-3">
            <SelectComponent
              placeholder="Type"
              // value={state.orderType}
              onChange={(value) => setState({ ...state, orderType: value })}
              options={[
                { value: '', label: 'ALL' },
                { value: 'BUY', label: 'Buy' },
                { value: 'SELL', label: 'Sell' },
              ]}
            />
          </div>
        )}
        {showPaymentMethod && (
          <div className="col-lg-3 mb-3">
            <SelectComponent
              placeholder="Method"
              onChange={(value) =>
                setState({
                  ...state,
                  paymentMethod: value,
                })
              }
              options={[
                { value: '', label: 'ALL' },
                ...Object.values(PaymentMethodEnum)
                  .filter(
                    (paymentMethod) => paymentMethod !== PaymentMethodEnum.None,
                  )
                  .map((method) => ({
                    value: method,
                    label: method,
                  })),
              ]}
            />
          </div>
        )}
        {showOrderStatus && (
          <div className="col-lg-3 mb-3">
            <SelectComponent
              placeholder="Order Status"
              onChange={(value) => setState({ ...state, orderStatus: value })}
              options={[
                { value: '', label: 'ALL' },
                ...Object.values(OrderStatus).map((status) => ({
                  value: status,
                  label: camelCaseToSpaced(status),
                })),
              ]}
            />
          </div>
        )}
        {showAccountType && (
          <div className="col-lg-3 mb-3">
            <SelectComponent
              placeholder="Account Type"
              onChange={(value) => setState({ ...state, userType: value })}
              options={[
                { value: '', label: 'ALL' },
                ...Object.values(UserType).map((status) => ({
                  value: status,
                  label: camelCaseToSpaced(status),
                })),
              ]}
            />
          </div>
        )}
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <SelectComponentValue
                placeholder="Search By"
                value={state.searchType}
                onChange={(value: any) => {
                  setState({ ...state, searchType: value as string })
                }}
                options={[
                  { value: 'name', label: 'Search By - Name' },
                  { value: 'email', label: 'Search By - Email' },
                ]}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <div
                style={{
                  position: 'relative',
                  zIndex: 0,
                }}
              >
                <SearchInput
                  onClick={getData}
                  value={state.searchTerm}
                  onChange={(e) =>
                    setState({ ...state, searchTerm: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {showDateRange && (
          <div className="col-lg-3 mb-3">
            <div className="double-datepicker">
              <SingleDatePickerComponent
                setDate={(d) => setState({ ...state, fromDate: d.toDate() })}
                date={state.fromDate ? moment(state.fromDate) : null}
                id="fromDate"
                placeholder="Start Date"
                isBefore={false}
              />
              <SingleDatePickerComponent
                setDate={(d) => setState({ ...state, toDate: d.toDate() })}
                date={state.toDate ? moment(state.toDate) : null}
                id="toDate"
                placeholder="End Date"
                isBefore={false}
              />
            </div>
          </div>
        )}

        <div className="col-lg-3 mb-3">
          <div className="d-flex align-items-center justify-content-between ">
            <button
              className="btn btn-primary pl-3 pr-3"
              disabled={state.currentPage === 1}
              onClick={() =>
                setState({ ...state, currentPage: state.currentPage - 1 })
              }
            >
              Prev
            </button>
            <p className="current-page-number ml-2 mr-2 mb-0">
              <strong>Page {state.currentPage}</strong>
            </p>
            <button
              className="btn btn-primary pl-3 pr-3"
              disabled={
                state.filteredData ? state.filteredData.length === 0 : true
              }
              onClick={() =>
                setState({ ...state, currentPage: state.currentPage + 1 })
              }
            >
              Next
            </button>
          </div>
        </div>
        {sortInputs}
        <div className="col-lg-3 mb-3">
          <QButton
            fullWidth
            loading={state.isExportDownloadLoading}
            onClick={handleExportOrders}
            className="ml-auto d-block"
            size="sm"
          >
            Download Orders
          </QButton>
        </div>
      </div>
      <div className="tr-filter-row"></div>
    </div>
  )
}
export const TableTopFiltersNewUIForBankPurchase = ({
  state,
  setState,
  getData,
  showDateRange = true,
  sortInputs = null,
  handleExportOrders,
}: any) => {
  return (
    <div className="tr-filter-wrapper">
      <div className="row">
        <div className="col-lg-3 mb-3">
          <div className="double-datepicker">
            <SingleDatePickerComponent
              setDate={(d) => setState({ ...state, fromDate: d.toDate() })}
              date={state.fromDate ? moment(state.fromDate) : null}
              id="fromDate"
              placeholder="Start Date"
              isBefore={false}
            />
            <SingleDatePickerComponent
              setDate={(d) => setState({ ...state, toDate: d.toDate() })}
              date={state.toDate ? moment(state.toDate) : null}
              id="toDate"
              placeholder="End Date"
              isBefore={false}
            />
          </div>
        </div>
        <div className="col-lg-3 mb-3">
          <SelectComponent
            placeholder="Unit Type"
            onChange={(value) => setState({ ...state, unitType: value })}
            options={[
              { value: '', label: 'ALL' },
              ...Object.values(UnitType)
                // .filter((v) => v !== '')
                .map((key) => ({
                  value: key,
                  label: UnitDisplayNames[key],
                })),
            ]}
          />
        </div>
        <div className="col-lg-3 mb-3">
          <SelectComponent
            placeholder="Account Type"
            onChange={(value) => setState({ ...state, userType: value })}
            options={[
              { value: '', label: 'ALL' },
              ...Object.values(UserType).map((key) => ({
                value: key,
                label: key,
              })),
            ]}
          />
        </div>

        <div className="col-lg-3 mb-3">
          <SelectComponentValue
            placeholder="Search By"
            value={state.searchType}
            onChange={(value: any) => {
              setState({ ...state, searchType: value as string })
            }}
            options={[
              { value: 'name', label: 'Search By - Name' },
              { value: 'email', label: 'Search By - Email' },
            ]}
          />
        </div>
        <div className="col-lg-3 mb-3">
          <div
            style={{
              position: 'relative',
              zIndex: 0,
            }}
          >
            <SearchInput
              onClick={getData}
              value={state.searchTerm}
              onChange={(e) =>
                setState({ ...state, searchTerm: e.target.value })
              }
            />
          </div>
        </div>
        {sortInputs}
        <div className="col-lg-3 mb-3">
          <div className="d-flex align-items-center justify-content-between ">
            <button
              className="btn btn-primary pl-3 pr-3"
              disabled={state.currentPage === 1}
              onClick={() =>
                setState({ ...state, currentPage: state.currentPage - 1 })
              }
            >
              Prev
            </button>
            <p className="current-page-number ml-2 mr-2 mb-0">
              <strong>Page {state.currentPage}</strong>
            </p>
            <button
              className="btn btn-primary pl-3 pr-3"
              disabled={
                state.filteredData ? state.filteredData.length === 0 : true
              }
              onClick={() =>
                setState({ ...state, currentPage: state.currentPage + 1 })
              }
            >
              Next
            </button>
          </div>
        </div>

        <div className="col-lg-3 mb-3 ">
          <QButton
            fullWidth
            loading={state.isExportDownloadLoading}
            onClick={handleExportOrders}
            className="ml-auto d-block"
            size="sm"
          >
            Download Orders
          </QButton>
        </div>
      </div>
    </div>
  )
}
export const TableTopFiltersNewUIForOtherPurchase = ({
  state,
  setState,
  getData,
  sortInputs = null,
  showDateRange = true,
  handleExportOrders,
}: any) => {
  return (
    <div className="tr-filter-wrapper">
      <div className="row">
        <div className="col-lg-3 mb-3">
          <div className="double-datepicker">
            <SingleDatePickerComponent
              setDate={(d) => setState({ ...state, fromDate: d.toDate() })}
              date={state.fromDate ? moment(state.fromDate) : null}
              id="fromDate"
              placeholder="Start Date"
              isBefore={false}
            />
            <SingleDatePickerComponent
              setDate={(d) => setState({ ...state, toDate: d.toDate() })}
              date={state.toDate ? moment(state.toDate) : null}
              id="toDate"
              placeholder="End Date"
              isBefore={false}
            />
          </div>
        </div>
        <div className="col-lg-3 mb-3">
          <SelectComponent
            placeholder="Currency"
            onChange={(value) => setState({ ...state, unitType: value })}
            options={[
              { value: '', label: 'ALL' },
              ...Object.values(UnitType)
                // .filter((v) => v !== '')
                .map((key) => ({
                  value: key,
                  label: UnitDisplayNames[key],
                })),
            ]}
          />
        </div>
        <div className="col-lg-3 mb-3">
          <SelectComponent
            placeholder="Account Type"
            onChange={(value) => setState({ ...state, userType: value })}
            options={[
              { value: '', label: 'ALL' },
              ...Object.values(UserType).map((key) => ({
                value: key,
                label: key,
              })),
            ]}
          />
        </div>

        <div className="col-lg-3 mb-3">
          <SelectComponentValue
            placeholder="Search By"
            value={state.searchType}
            onChange={(value: any) => {
              setState({ ...state, searchType: value as string })
            }}
            options={[
              { value: 'name', label: 'Search By - Name' },
              { value: 'email', label: 'Search By - Email' },
            ]}
          />
        </div>
        <div className="col-lg-3 mb-3">
          <div
            style={{
              position: 'relative',
              zIndex: 0,
            }}
          >
            <SearchInput
              onClick={getData}
              value={state.searchTerm}
              onChange={(e) =>
                setState({ ...state, searchTerm: e.target.value })
              }
            />
          </div>
        </div>

        <div className="col-lg-3 mb-3">
          <div className="d-flex align-items-center justify-content-between ">
            <button
              className="btn btn-primary pl-3 pr-3"
              disabled={state.currentPage === 1}
              onClick={() =>
                setState({ ...state, currentPage: state.currentPage - 1 })
              }
            >
              Prev
            </button>
            <p className="current-page-number ml-2 mr-2 mb-0">
              <strong>Page {state.currentPage}</strong>
            </p>
            <button
              className="btn btn-primary pl-3 pr-3"
              disabled={
                state.filteredData ? state.filteredData.length === 0 : true
              }
              onClick={() =>
                setState({ ...state, currentPage: state.currentPage + 1 })
              }
            >
              Next
            </button>
          </div>
        </div>
        {sortInputs}
        <div className="col-lg-3 mb-3">
          <QButton
            fullWidth
            loading={state.isExportDownloadLoading}
            onClick={handleExportOrders}
            className="ml-auto d-block"
            size="sm"
          >
            Download Orders
          </QButton>
        </div>
      </div>
    </div>
  )
}

export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
  'clean',
]
export const CustomReactQuillV3 = forwardRef<ReactQuill, any>((props, ref) => {
  const quillRef = useRef<ReactQuill>(null)
  const [imageLoading, setimageLoading] = useState(false)

  const handleImageUpload = useCallback(() => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files?.[0]
      if (file) {
        const formData = new FormData()
        formData.append('image', file)

        try {
          setimageLoading(true)
          const response = await addImage(formData)
          //@ts-ignore
          const imageUrl = response.data.fileUrl

          console.log('quill.current:', quillRef)
          // @ts-ignore
          const quill = quillRef.current?.getEditor()
          const range = quill?.getSelection()?.index

          if (range !== undefined && range >= 0) {
            quill?.insertEmbed(range, 'image', imageUrl)
          }
          setimageLoading(false)
        } catch (error) {
          setimageLoading(false)
          console.error('Error uploading image:', error)

          alert('Error uploading image')
        }
      }
    }
  }, [])
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ['bold', 'italic', 'underline', 'blockquote'],
          [{ color: [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          // image: imageHandler,
          image: handleImageUpload,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    // [imageHandler],
    [handleImageUpload],
  )
  return (
    <div className="text-editor-wrapper">
      {imageLoading && <LoadingBlock />}
      <ReactQuill
        style={{
          height: 500,
          marginBottom: 60,
        }}
        formats={formats}
        modules={modules}
        ref={quillRef}
        value={props.value}
        onChange={props.onChange}
        {...props}
      />
    </div>
  )
})
export const HasToolTipLabel = ({
  id,
  txt,
  htmlFor,
  labelText,
}: {
  id: string
  txt: string
  htmlFor: string
  labelText: string
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipAction, setTooltipAction] = useState('')
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768)
  const menuTriggerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleDocumentClick = (event: any) => {
    // if (!event.target.closest(`#${stringToSlug(td.label)}`)) {
    //     setTooltipOpen(false);
    //     setTooltipAction("");
    // }

    if (
      event.target !== menuTriggerRef.current &&
      !menuTriggerRef.current?.contains(event.target as Node)
    ) {
      setTooltipOpen(false)
      setTooltipAction('')
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const handleMouseOver = () => {
    if (isDesktop) {
      setTooltipOpen(true)
    }
  }

  const handleMouseOut = () => {
    if (isDesktop && tooltipAction !== 'click') {
      setTooltipOpen(false)
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    setTooltipAction('click')

    setTooltipOpen(true)
  }

  return (
    <span className="tooltip-label">
      <label className="font-weight-bold " htmlFor={htmlFor}>
        {labelText}
      </label>
      <span
        className="tooltip-info-icon"
        id={id}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        // onTouchStart={handleClick}
        ref={menuTriggerRef}
      >
        <i className="fa-solid fa-circle-info"></i>
      </span>
      <Tooltip
        placement="top"
        target={id}
        isOpen={tooltipOpen}
        // toggle={toggle} // do not remove this comment, with toggle hadleys feedback based ui does not work
        autohide={false}
        trigger="click"
      >
        {txt}
      </Tooltip>
    </span>
  )
}

export const TRInfo = ({ label, value }: { label: string; value: any }) => {
  return (
    <div className="TRInfo">
      <p className="label">{label}</p>
      <p className="value">{value}</p>
    </div>
  )
}

interface SCOption {
  value: string
  label: string
}

interface SelectComponentProps {
  options: SCOption[]
  // value: string | null;
  onChange: (value: string | null) => void
  placeholder?: string
  isClearable?: boolean
}
const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
})

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#f0151f' : '#fff',
    color: state.isSelected ? '#fff' : '#000',
    ':hover': {
      backgroundColor: '#f0151f',
      color: '#fff',
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? '#f0151f' : '#ccc',
    boxShadow: state.isFocused ? '0 0 0 1px #f0151f' : 'none',
    ':hover': {
      borderColor: state.isFocused ? '#f0151f' : '#ccc',
    },
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#f0151f',
    ':hover': {
      color: '#f0151f',
    },
  }),
}
const colourStyles: StylesConfig<any> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? 'rgba(0,0,0,.1)'
            : undefined,
      color: '#ccc',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? 'red'
            : 'rgba(0,0,0,.1)'
          : undefined,
      },
    }
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, backgroundColor: 'red' }),
}
export const SelectComponent: React.FC<SelectComponentProps> = ({
  options,
  // value,
  onChange,
  placeholder = 'Select an option',
  isClearable = true,
}) => {
  const handleChange = (selectedOption: SCOption | null) => {
    onChange(selectedOption ? selectedOption.value : '')
  }

  return (
    <Select
      styles={customStyles}
      className="SelectComponent"
      options={options}
      // value={options.find(option => option.value === value) || null}
      onChange={handleChange}
      placeholder={placeholder}
      // menuIsOpen={true}
      isClearable={isClearable}
    />
  )
}
export const SelectComponentValue: React.FC<{
  options: SCOption[]
  value: string | null
  onChange: (value: string | null) => void
  placeholder?: string
  isClearable?: boolean
}> = ({
  options,
  value,
  onChange,
  placeholder = 'Select an option',
  isClearable = false,
}) => {
  const handleChange = (selectedOption: SCOption | null) => {
    onChange(selectedOption ? selectedOption.value : null)
  }

  return (
    <Select
      styles={customStyles}
      className="SelectComponent"
      options={options}
      value={options.find((option) => option.value === value) || null}
      onChange={handleChange}
      placeholder={placeholder}
      isClearable={isClearable}
    />
  )
}

export default function LoadingBlock({ ...props }: SpinnerProps) {
  return (
    <div
      className="LoadingBlock"
      style={{
        height: '30vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner color="primary" {...props}>
        Loading...
      </Spinner>
    </div>
  )
}

const MobileNavDownArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
  >
    <path
      d="M16 9.06201L8.61949 16.5001L1.23077 9.06201"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M8.61914 15.8632L8.61914 0.499512"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
)

export const ControlledCollapseMenuItem = ({
  childCollapse,
  linkList,
  menuTitle,
  isSideMenuOpen,
  toggleNestedCollapse,
  id,
  linkClickAction,
  ...props
}: {
  childCollapse?: any
  id: string
  menuTitle?: ReactNode
  isSideMenuOpen: boolean
  toggleNestedCollapse: (id: string) => void
  linkClickAction?: (e: React.MouseEvent<HTMLLinkElement>) => void
  linkList: {
    menuTitle: string
    path: string
  }[]

  props?: any
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  useEffect(() => {
    if (!isSideMenuOpen) {
      setIsOpen(false)
    }
  }, [isSideMenuOpen])
  return (
    <div className="row">
      <li
        className={`has-nested-item col-md-5 ${isOpen ? 'expanded' : ''}`}
        onClick={() => toggle()}
        {...props}
      >
        <span className="link-item">
          {menuTitle} <MobileNavDownArrow />
        </span>
        <ul className={`collapse ${isOpen ? 'show' : ''}`}>
          {linkList.map((l) => {
            return (
              <li key={l.path + id}>
                <Link
                  //@ts-ignore
                  onClick={linkClickAction}
                  className="link-item"
                  to={l.path}
                >
                  {l.menuTitle}
                </Link>
              </li>
            )
          })}
        </ul>
      </li>
    </div>
  )
}

export const ControlledCollapseMenuItemNEW: React.FC<
  ControlledCollapseMenuItemNEWProps
> = ({
  index,
  AuthMenuItems,
  userRoles,
  isSuperAdmin,
  isSideMenuOpen,
  collapseID,
  setcollapseID,
  linkClickAction,
}) => {
  // const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isSideMenuOpen) {
      setcollapseID('')
      // setIsOpen(false);
    }
  }, [isSideMenuOpen])

  const menuItem = AuthMenuItems[index]

  const toggle = () => {
    setcollapseID(menuItem.id === collapseID ? 'setcollapseID' : menuItem.id)
  }
  let isOpen = menuItem.id === collapseID

  return (
    <div className="">
      <li
        className={`ml-auto has-nested-item  ${isOpen ? 'expanded' : ''}`}
        onClick={toggle}
      >
        <span className="link-item">
          {menuItem.parentMenuTitle} <MobileNavDownArrow />
        </span>
        <Collapse
          tag={'ul'}
          isOpen={isOpen}
          // className={`collapse ${isOpen ? 'show' : ''}`}
        >
          {menuItem.menuList.map((link: any) => {
            // Check if the user has the required role or is a super admin
            const hasAccess =
              isSuperAdmin ||
              link.roles.some((role: AdminRoles) => userRoles.includes(role))

            return hasAccess ? (
              <li key={`${link.path}-${menuItem.id}`}>
                <Link
                  onClick={linkClickAction}
                  className="link-item"
                  to={link.path}
                >
                  {link.menuTitle}
                </Link>
              </li>
            ) : null
          })}
        </Collapse>
      </li>
    </div>
  )
}

export const DashComponentCommon = ({
  title = 'Holdings',
  img,
  updateDate = 'Updated 16 June 2024',
  tableData,
  pdfDownloadButton,

  // chartTickData = [1.0, 1.05, 1.1, 1.15, 1.2, 1.25, 1.3],
  // chartData = [
  //     {
  //         year: "2021",
  //         price: 1,
  //     },
  //     {
  //         year: "2022",
  //         price: 1,
  //     },
  //     {
  //         year: "2023",
  //         price: 1,
  //     },
  //     {
  //         year: "2024",
  //         price: 1,
  //     },
  // ],
}: DashComponentCommonPropType) => {
  return (
    <section className="folder-comp DashComponentCommon">
      <div className="container">
        <div className="row">
          <div className="title col-lg-4">
            <h4>{title}</h4>
          </div>
        </div>
        <div className="row body-section">
          <div className="col-lg-6 data-column">
            <h4 className="updateDate">{updateDate}</h4>

            <ul className="dash-table">
              {tableData.map((td: any) => (
                <li key={title + td.label}>
                  {/* <span className="label">{td.label}</span> */}

                  <>
                    <span className="label">
                      <span>{td.label}</span>
                    </span>
                  </>

                  <span className="value">
                    {formatNumberWithCommas(td.value)}
                  </span>
                </li>
              ))}
            </ul>
            {pdfDownloadButton && pdfDownloadButton}
          </div>
          <div className="col-lg-6 data-column chart">{img}</div>
        </div>
      </div>
    </section>
  )
}

export const FloatText = ({
  color = 'red',
  content,
  classNames,
  orientation = 'left',
}: {
  color: string
  classNames?: string
  orientation?: string
  content: ReactElement
}) => {
  return (
    <div className={`float-text ${color}-bg ${classNames} ${orientation}`}>
      <div className="text-content">{content}</div>
      {/* <h1 className="title">
              Sustainable land, farmed with the future in mind, returning a high 7.5% per year.
              <br />
              <br />
              It’s all good.
          </h1> */}
      <div className="overlay"></div>
    </div>
  )
}
export const FloatTextV2 = ({
  color = 'red',
  content,
  classNames,
  orientation = 'left',
}: {
  color: string
  classNames?: string
  orientation?: string
  content: ReactElement
}) => {
  return (
    <div className={`float-text-v2 ${color}-bg ${classNames} ${orientation}`}>
      <div className="text-content">{content}</div>
      {/* <h1 className="title">
              Sustainable land, farmed with the future in mind, returning a high 7.5% per year.
              <br />
              <br />
              It’s all good.
          </h1> */}
      <div className="overlay"></div>
    </div>
  )
}
export const FloatTextTitle = ({
  color,
  title,
}: {
  color?: string

  title: ReactElement | string
}) => {
  return (
    <div className="float-section-title">
      <h5>
        {title}
        <div className="overlay-title"></div>
      </h5>
    </div>
  )
}
