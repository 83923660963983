import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import './index.scss'
import {
  UnitType,
  ExternalCardCryptoFeeFormData,
  UnitPrices,
  UnitDisplayNames,
} from '../../utils/types'
import {
  getCurrencyUnitsApi,
  getExternalfee,
  updateCurrencyUnitFeesApi,
  updateExternalFeesApi,
} from '../../api'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const FeeManagement = () => {
  const [unitPrices, setUnitPrices] = useState<UnitPrices[]>([])
  const [formData, setFormData] = useState({
    unitType: '',
    buyOrderTransactionFeeInCents: 0,
    sellOrderTransactionFeeInUnits: 0,
    secondaryMarketTransactionPercentage: 0,
  })
  const [isLoading, setIsLoading] = useState(false)

  const [selectedUnitPricing, setSelectedUnitPricing] =
    useState<UnitPrices | null>(null)
  const [updatedUnitPricing, setUpdatedUnitPricing] =
    useState<UnitPrices | null>(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const toggle = () => setModalOpen(!isModalOpen)

  const [externalFees, setExternalFees] =
    useState<ExternalCardCryptoFeeFormData>({
      cardFeePercentage: 0,
      cardFeeCents: 0,
      cryptoFeePercentage: 0,
      cryptoFeeCents: 0,
      extraFees: 0,
    })

  const [updatedExternalFees, setUpdatedExternalFees] =
    useState<ExternalCardCryptoFeeFormData>({
      cardFeePercentage: 0,
      cardFeeCents: 0,
      cryptoFeePercentage: 0,
      cryptoFeeCents: 0,
      extraFees: 0,
    })

  const getExternalFeeDetails = async () => {
    try {
      const fetchedExternalFees = (await getExternalfee()).data?.data

      const {
        cardFeePercentage,
        cardFeeCents,
        cryptoFeePercentage,
        cryptoFeeCents,
        extraFees,
      } = fetchedExternalFees

      const feesData = {
        cardFeePercentage,
        cardFeeCents,
        cryptoFeePercentage,
        cryptoFeeCents,
        extraFees,
      }

      setExternalFees(feesData)
      setUpdatedExternalFees(feesData)
    } catch (error: any) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  const getCurrencyUnits = async () => {
    getCurrencyUnitsApi()
      .then((res) => {
        setUnitPrices(res.data)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }

  const handleExternalFeeSubmit = async () => {
    try {
      const isExternalPricingChanges =
        JSON.stringify(externalFees) !== JSON.stringify(updatedExternalFees)
      if (!isExternalPricingChanges)
        return alert('data hasnot been changed yet')
      const { data: externalFeeAfterUpdate, message: successMessage } = (
        await updateExternalFeesApi(updatedExternalFees)
      ).data

      toast.success(successMessage)
      setExternalFees(externalFeeAfterUpdate)
      setUpdatedExternalFees(externalFeeAfterUpdate)
    } catch (error: any) {
      console.log('error', error)
      if (error.code === 'ERR_BAD_REQUEST') {
        // alert(`${error.response.data.message}`)
        toast.error(error.response.data.message)
      } else if (error.code === 'ERR_NETWORK') {
        // alert(`${error.message}`)
        toast.error('Server down')
      }
    }
  }

  const handleSubmit = async () => {
    if (!updatedUnitPricing) return
    const isUnitPricingChanges =
      JSON.stringify(selectedUnitPricing) !== JSON.stringify(updatedUnitPricing)
    if (!isUnitPricingChanges) return alert('data hasnot been changed yet')

    setIsLoading(true)
    try {
      const { data: unitPriceUpdated, message: successMessage } = (
        await updateCurrencyUnitFeesApi(updatedUnitPricing)
      ).data
      toast.success(successMessage)
      await getCurrencyUnits()

      setUpdatedUnitPricing(unitPriceUpdated)
      setSelectedUnitPricing(unitPriceUpdated)
      setIsLoading(false)
    } catch (error: any) {
      console.log('error', error)
      toast.error(error.response.data.message)
      setIsLoading(false)
    }
  }

  const handleExternalFeeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target
    setUpdatedExternalFees((prevFees) => ({
      ...prevFees,
      [name]: value === '' ? 0 : parseFloat(value), // Set to 0 if left empty
    }))
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setUpdatedUnitPricing((prev) => ({
      ...prev!,
      [name]: value === '' ? 0 : parseFloat(value), // Set to 0 if left empty
    }))
  }
  // Open the modal with the selected unit's data
  const handleUpdateClick = (unit: UnitPrices) => {
    setSelectedUnitPricing(unit)
    setUpdatedUnitPricing(unit)
    setModalOpen(true)
  }

  useEffect(() => {
    getCurrencyUnits()
    getExternalFeeDetails()
  }, [])
  return (
    <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
      <div className="content-wrapper ">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li
              className="breadcrumb-item"
              style={{
                paddingTop: '2rem',
                paddingBottom: '0.6rem',
              }}
            >
              <Link to="/">Dashboard</Link>
            </li>
            <li
              className="breadcrumb-item active"
              style={{
                paddingTop: '2rem',
                paddingBottom: '0.6rem',
              }}
            >
              Fees Management
            </li>
          </ol>

          <div className="card mb-3">
            <div className="card-body">
              <h3>Update Internal Fees</h3>
              <div className="table-responsive">
                <table
                  className="table table-bordered text-center"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>Unit Type</th>
                      <th>Buy Order Fee (in Cents)</th>
                      <th>Sell Order Fee (in Units)</th>
                      <th>Secondhand Units sold as % of all Units sold</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unitPrices.map((unit: UnitPrices) => (
                      <tr key={unit.unitType}>
                        {/* @ts-ignore */}
                        <td>{UnitDisplayNames[unit.unitType]}</td>
                        <td>{unit.buyOrderTransactionFeeInCents}</td>
                        <td>{unit.sellOrderTransactionFeeInUnits}</td>
                        <td>{unit.secondaryMarketTransactionPercentage}</td>
                        <td>
                          <button
                            onClick={() => handleUpdateClick(unit)}
                            className="btn btn-primary"
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Modal
                  isOpen={isModalOpen}
                  toggle={toggle}
                  centered
                  scrollable
                  onClosed={() => {
                    setSelectedUnitPricing(null)
                    setUpdatedUnitPricing(null)
                  }}
                >
                  <ModalHeader toggle={toggle}>
                    Update{' '}
                    <b>
                      {/* @ts-ignore */}
                      {UnitDisplayNames[updatedUnitPricing?.unitType]}
                    </b>{' '}
                    Price details
                  </ModalHeader>
                  <ModalBody>
                    {updatedUnitPricing && (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Buy Order Fee (in Cents)</label>
                            <input
                              type="text"
                              className="form-control"
                              name="buyOrderTransactionFeeInCents"
                              value={
                                updatedUnitPricing.buyOrderTransactionFeeInCents
                              }
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Sell Order Fee (in Units)</label>
                            <input
                              type="number"
                              min={1}
                              name="sellOrderTransactionFeeInUnits"
                              className="form-control"
                              value={
                                updatedUnitPricing.sellOrderTransactionFeeInUnits
                              }
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Second hand Units sold as (%) from buyer
                            </label>
                            <div className="input-group mb-3">
                              <input
                                type="number"
                                name="secondaryMarketTransactionPercentage"
                                className="form-control"
                                min={0}
                                max={100}
                                value={
                                  updatedUnitPricing.secondaryMarketTransactionPercentage
                                }
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => {
                        toggle()
                        setSelectedUnitPricing(null)
                        setSelectedUnitPricing(null)
                      }}
                      disabled={isLoading}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled={isLoading}
                      onClick={handleSubmit}
                    >
                      Update{' '}
                      {isLoading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </ModalFooter>
                </Modal>
              </div>
              <div className="box_general padding_bottom add-land-form">
                <h3>Update External Card and Crypto Fees</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mt-4">
                      <div>
                        <div className="row">
                          <div className="d-flex align-items-center">
                            <label className="mr-2">
                              <b>Cards Fee Percentage</b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="number"
                              className="form-control "
                              placeholder=""
                              min={0}
                              max={100}
                              name="cardFeePercentage"
                              value={updatedExternalFees.cardFeePercentage}
                              onChange={handleExternalFeeInputChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="d-flex align-items-center">
                            <label className="mr-2">
                              <b>Card Fixed Fee in Cents</b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              className="form-control"
                              type="number"
                              min={0}
                              name="cardFeeCents"
                              value={updatedExternalFees.cardFeeCents}
                              onChange={handleExternalFeeInputChange}
                            />
                          </div>
                        </div>

                        <br />
                        <div className="row mb-3">
                          <div className="d-flex align-items-center">
                            <label className="mr-2">
                              <b>Crypto Fee Percentage</b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="number"
                              className="form-control "
                              placeholder=""
                              min={0}
                              name="cryptoFeePercentage"
                              value={updatedExternalFees.cryptoFeePercentage}
                              onChange={handleExternalFeeInputChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="d-flex align-items-center">
                            <label className=" mr-2">
                              <b>Crypto Fee Cents</b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="number"
                              className="form-control "
                              placeholder=""
                              min={0}
                              name="cryptoFeeCents"
                              value={updatedExternalFees.cryptoFeeCents}
                              onChange={handleExternalFeeInputChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="d-flex align-items-center">
                            <label className="mr-2">
                              <b>Extra Fee (for future use) </b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              className="form-control"
                              type="number"
                              min={0}
                              name="extraFees"
                              value={updatedExternalFees.extraFees}
                              onChange={handleExternalFeeInputChange}
                            />
                          </div>
                        </div>

                        <button
                          className="btn_1 rounded  color btnCenter"
                          onClick={handleExternalFeeSubmit}
                        >
                          Update External Fees
                        </button>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          {/* <h4 style={{ color: "red" }}>{msg}</h4> */}
        </div>
      </div>
    </body>
  )
}
export default FeeManagement
