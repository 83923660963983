/* eslint-disable no-lone-blocks */
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  ONE_AUD_TO_CENTS,
  formatDate,
  getDate,
  getEnteredByName,
} from '../../utils/utilities'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {
  UnitType,
  PaymentMethodEnum,
  UnitDisplaySymbol,
  UnitDisplayNames,
  CurrencyDisplaySymbol,
  CurrencyType,
} from '../../utils/types'
import {
  getUserbuyOrderPaymentSentWithoutCardAndBank,
  postApproveBuyOtherOrder,
  postDisApproveBuyOrder,
} from '../../api'
import {
  HasToolTipLabel,
  SelectComponentValue,
  SortableTableHeader,
  TableTopFilters,
  TableTopFiltersNewUI,
  TableTopFiltersNewUIForOtherPurchase,
  TRInfo,
} from '../../component/misc/smallComponents'
import { toast } from 'react-toastify'
import { SvgIcons } from '../../component/misc/SvgIcons'
import { camelCaseToSpaced } from '../../utils/common'

const Userpurchse = () => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const [state, setState] = useState<any>({
    sortDirection: 'desc',
    isExportDownloadLoading: false,
    dataList: [],
    filteredData: [],
    currentPage: 1,
    pageSize: 50,
    searchTerm: '',
    searchType: 'name',
    unitType: '',
    paymentMethod: '',
    userType: '',
    fromDate: '',
    toDate: '',
    sortBy: '',
  })

  const [orderId, setOrderId] = useState<any>('')

  const getData = async () => {
    const {
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      userType,
      unitType,
    } = state
    getUserbuyOrderPaymentSentWithoutCardAndBank(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      userType,
      unitType,
    )
      .then((res) => {
        setState((prevState: any) => ({
          ...prevState,
          dataList: res.data,
          filteredData: res.data,
        }))
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }

  const handleApprove = () => {
    const data = {
      orderId: orderId,
    }

    postApproveBuyOtherOrder(data)
      .then((res: any) => {
        getData()
        toast.success(res.data.message)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }
  const handleDisApprove = () => {
    const data = {
      orderId: orderId,
    }

    postDisApproveBuyOrder(data)
      .then((res: any) => {
        getData()
        toast.success(res.data.message)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }
  useEffect(() => {
    getData()
  }, [
    state.currentPage,
    state.fromDate,
    state.toDate,
    state.unitType,
    state.userType,
  ])

  useEffect(() => {
    filterAndSortOrders()
  }, [
    // state.searchTerm,
    // state.searchType,
    state.startDate,
    state.endDate,
    state.sortBy,
    state.sortDirection,
    state.dataList.length,
  ])

  const filterAndSortOrders = () => {
    let filtered = state.dataList

    if (state.sortBy) {
      filtered = filtered.sort((a: any, b: any) => {
        let comparison = 0

        if (state.sortBy === 'units') {
          const unitsA = a.currencyUnit?.numberOfUnits || 0
          const unitsB = b.currencyUnit?.numberOfUnits || 0
          comparison = unitsB - unitsA
        } else if (state.sortBy === 'amount') {
          const amountA = a.paymentDetails?.paymentAmount || 0
          const amountB = b.paymentDetails?.paymentAmount || 0
          comparison = amountB - amountA
        }

        return state.sortDirection === 'asc' ? -comparison : comparison
      })
    }

    setState((prevState: any) => ({ ...prevState, filteredData: filtered }))
  }

  const handleExportOrders = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        userType,
        unitType,
      } = state
      const response = await getUserbuyOrderPaymentSentWithoutCardAndBank(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        userType,
        unitType,
        true,
      )

      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }
  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: 'desc',
      dataList: [],
      filteredData: [],
      searchTerm: '',
      searchType: 'name',
      unitType: '',
      orderType: '',
      paymentMethod: '',
      startDate: null,
      endDate: null,
      sortBy: '',
    })
    getData()
  }
  const handleSortTableHead = (property: string) => {
    setState((prevState: any) => {
      const newSortDirection =
        property === prevState.sortBy
          ? prevState.sortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : prevState.sortDirection
      return { ...prevState, sortBy: property, sortDirection: newSortDirection }
    })
  }
  const { sortBy, sortDirection } = state
  return (
    <>
      <div className="card-ui-pages-content-wrapper mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ol
                className="breadcrumb"
                style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Other Purchase Order</li>
              </ol>

              <div className=" mb-3">
                <div className="">
                  <TableTopFiltersNewUIForOtherPurchase
                    state={state}
                    setState={setState}
                    getData={getData}
                    handleExportOrders={handleExportOrders}
                    sortInputs={
                      <>
                        <div className="col-lg-3 mb-3">
                          {/* <Label className="fz14 fw-semibold mb-0">Sort Order</Label> */}
                          <SelectComponentValue
                            value={sortDirection}
                            placeholder="Sort Order"
                            onChange={(sortDirection) =>
                              // setFilterPaymentMethod(value)
                              // @ts-ignore
                              setState((prev) => ({ ...prev, sortDirection }))
                            }
                            options={[
                              { value: 'asc', label: 'Ascending' },
                              { value: 'desc', label: 'Descending' },
                            ]}
                          />
                        </div>
                        <div className="col-lg-3 mb-3">
                          {/* <Label className="fz14 fw-semibold mb-0">Sory By</Label> */}
                          <SelectComponentValue
                            value={sortBy}
                            placeholder="Sory By"
                            onChange={(sortBy) => {
                              // @ts-ignore
                              handleSortTableHead(sortBy)
                            }}
                            options={[
                              { label: 'Number of Units', value: 'units' },
                              { label: 'Value', value: 'amount' },
                            ]}
                          />
                        </div>
                      </>
                    }
                  />
                  {state?.filteredData &&
                    state.filteredData.map((item: any, index: number) => (
                      <div className="tr-card">
                        <div className="info-icon-wrap">
                          <div className="ic-label-wrap">
                            <div className="ic-label withbg">
                              {SvgIcons.MoneyCash}
                              <span>{camelCaseToSpaced(item.orderStatus)}</span>
                            </div>
                          </div>
                          <div className="ic-label-wrap">
                            <div className="ic-label withbg">
                              {SvgIcons.CheckCircle}
                              <span>
                                {
                                  UnitDisplayNames[
                                    item.currencyUnit.unitType as UnitType
                                  ]
                                }{' '}
                                ({item.currencyUnit.numberOfUnits}) -{' '}
                                {item.paymentDetails.paymentAmount /
                                  ONE_AUD_TO_CENTS}{' '}
                                {
                                  CurrencyDisplaySymbol[
                                    item.paymentDetails.currency as CurrencyType
                                  ]
                                }
                              </span>
                            </div>
                          </div>
                          <div className="ic-label-wrap">
                            <div
                              className={
                                item.orderType !== 'BUY'
                                  ? 'ic-label up'
                                  : 'ic-label'
                              }
                            >
                              {SvgIcons.ArrowDown}
                              <span>{item.orderType}</span>
                            </div>
                          </div>
                        </div>
                        <div className="info-text">
                          <div className="info-text-col-wrap">
                            <div className="info-col">
                              <div className="tr-info-wrap">
                                <TRInfo
                                  value={
                                    item.user?.firstName +
                                    ' ' +
                                    item?.user?.lastName
                                  }
                                  label={'User Name'}
                                />
                              </div>
                              <div className="tr-info-wrap">
                                <TRInfo
                                  value={getDate(
                                    item.createdAt?.toLocaleString(),
                                  )}
                                  label={'Created Date'}
                                />
                              </div>
                            </div>
                            <div className="info-col">
                              <div className="tr-info-wrap">
                                <TRInfo
                                  value={item.user.email}
                                  label={'Email'}
                                />
                              </div>
                              <div className="tr-info-wrap">
                                <TRInfo
                                  value={
                                    item.transfer?.recipientEmail
                                      ? item.transfer.recipientEmail
                                      : '--'
                                  }
                                  label={'Received Email'}
                                />
                              </div>
                            </div>

                            <div className="info-col">
                              <div className="tr-info-wrap">
                                <TRInfo
                                  value={
                                    item.paymentDetails?.paymentMethod
                                      ? item.paymentDetails?.paymentMethod
                                      : '--'
                                  }
                                  label={'Payment Method'}
                                />
                              </div>

                              <div className="tr-info-wrap">
                                <TRInfo
                                  value={
                                    item.buy?.paymentId
                                      ? item.buy.paymentId
                                      : '--'
                                  }
                                  label={'Refrence Number'}
                                />
                              </div>
                            </div>
                            <div className="info-col">
                              <p className="label">Approve/Dis-Approve</p>
                              <QButton
                                className="mt-3"
                                onClick={() => {
                                  setOrderId(item.orderId)
                                  toggle()
                                }}
                              >
                                Action
                              </QButton>
                            </div>
                          </div>
                          {/* <p className='comments'>Note(Optional) : Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti, nostrum?</p> */}
                          {item.buy?.comments && (
                            <p className="comments">
                              Note(Optional) : {item.buy?.comments}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
          >
            <ModalHeader toggle={toggle}>
              Approve/Dis-Approve Other Purchase Order
            </ModalHeader>
            <ModalBody>
              <div className="d-flex justify-content-between">
                <Button
                  color="success"
                  onClick={() => {
                    handleApprove()
                    toggle()
                  }}
                >
                  Approve
                </Button>

                <Button
                  color="primary"
                  onClick={() => {
                    handleDisApprove()
                    toggle()
                  }}
                >
                  Dis-Approve
                </Button>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>{' '}
              {/* <Button
                      color="primary"
                      onClick={() => {
                        handleApprove()
                        toggle()
                      }}
                    >
                      Approve
                    </Button> */}
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default Userpurchse
