import { useEffect, useState } from 'react'
import { getStatistics } from '../../api'
import {
  CurrencyStatistics,
  Statistics,
  UnitDisplayNames,
  UnitType,
} from '../../utils/types'
import {
  DashComponentCommon,
  FloatText,
} from '../../component/misc/smallComponents'
import moment from 'moment'
import { formatNumberWithCommas } from '../../utils/utilities'
import { ONE_USD_TO_CENTS } from '../../utils/constants'
export default function Addmin() {
  const [statistics, setStatistics] = useState<Statistics>()
  const [isLoading, setLoading] = useState(false)

  const getStaticsData = async () => {
    setLoading(true)
    getStatistics()
      .then((res) => {
        setLoading(false)
        setStatistics(res.data)
      })
      .catch((error: any) => {
        console.log('error', error)
        setLoading(false)
      })
  }
  useEffect(() => {
    getStaticsData()
  }, [])
  const renderTable = (currencies: { [key: string]: CurrencyStatistics }) => {
    // Define the columns (statistics to display)
    const columns = [
      { key: 'totalPendingPurchase', label: 'Pending Purchase' },
      { key: 'totalPurchase', label: 'Purchase Complete' },
      { key: 'totalPendingSell', label: 'Pending Sale' },
      { key: 'totalSold', label: 'Total Units sold' },
      {
        key: 'totalProjectLandValueInCents',
        label: 'Total land value in ecosystem',
      },
    ]

    return (
      <div className="tr-card-list">
        <h4>Currency Statistics</h4>
        <table
          className="table table-bordered text-nowrap text-center"
          id="dataTable"
          width="100%"
          cellSpacing="0"
        >
          {' '}
          <div className="">
            <div className="info-icon-wrap">
              <thead>
                <tr>
                  <th>Currency</th>
                  {columns.map((col) => (
                    <th key={col.key}>{col.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.entries(currencies).map(([currencyKey, stats]) => (
                  <tr key={currencyKey}>
                    {/* @ts-ignore */}
                    <th>{UnitDisplayNames[currencyKey]}</th>
                    {columns.map((col) => {
                      let value = stats[col.key as keyof CurrencyStatistics]

                      // Check if the key is 'totalProjectLandValueInCents' and divide it by 100
                      if (
                        col.key === 'totalProjectLandValueInCents' &&
                        typeof value === 'number'
                      ) {
                        value = value / ONE_USD_TO_CENTS
                      }

                      return (
                        <td key={col.key}>
                          {formatNumberWithCommas(value as number) ?? 'N/A'}
                        </td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            </div>
          </div>
        </table>
      </div>
    )
  }

  return (
    <div className="container-based-wrapper">
      <div className="container">
        {/* <ol
          className="breadcrumb"
          style={{ paddingTop: '1rem', paddingBottom: '0.6rem' }}
        >
          <li
            className="breadcrumb-item active"
            style={{ color: '#F0151F' }}
          >
            Admin Dashboard
          </li>
        </ol> */}
        <div className="row">
          <div className="col-lg-4">
            <FloatText
              color="yellow"
              classNames="black-color min-height-auto page-head"
              content={
                <>
                  <h4>Admin Dashboard</h4>
                  <h1>Hello </h1>
                </>
              }
            />
          </div>
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div className="user-dash-tabl py-2 admin-dashboard-table-1">
            {statistics && (
              <DashComponentCommon
                title="Admin Dashboard"
                updateDate={`Updated ${moment().format('Do MMMM YYYY')}`}
                img={
                  <img
                    className="img-fluid"
                    src="/assets/images/charts/LAND_UNIT_VALUE.png"
                    alt=""
                  />
                }
                tableData={[
                  {
                    label: 'Personal Users registered',
                    value: statistics.registeredUser,
                  },
                  {
                    label: 'Business Users registered',
                    value: statistics.registeredBusiness,
                  },
                  {
                    label: 'Personal Users approved',
                    value: statistics.approvedUser,
                  },
                  {
                    label: 'Business Users approved',
                    value: statistics.approvedBusiness,
                  },
                  {
                    label: 'Total Project Count',
                    value: statistics.projectCount,
                  },
                  // {
                  //   label: 'Total AUD in Bank',
                  //   value: statistics.totalAudInBank,
                  // },

                  {
                    label: 'Purchase Order',
                    value: statistics.buyOrderCount,
                  },
                  {
                    label: 'Sell Order',
                    value: statistics.sellOrderCount,
                  },
                ]}
              />
            )}
            {statistics &&
              statistics.currencies &&
              renderTable(statistics.currencies)}

            {/* <div className="table-responsive">
              <table
                className="table table-borderless table-striped "
                width="100%"
                cellSpacing="0"
              >
                {statistics && (
                  <tbody>
                    <tr>
                      <th>Total Approved Users Count</th>
                      <td>
                        <span className="q-badge">
                          {statistics.approveUserCount}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Total Approved Community Agent Count</th>
                      <td>
                        <span className="q-badge">
                          {statistics.approveUserCommunityAgentCount}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Total Project Count</th>
                      <td>
                        <span className="q-badge">
                          {statistics.projectCount}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Total AUD in Bank</th>
                      <td>
                        <span className="q-badge">
                          {parseFloat(
                            statistics.totalAudInBank.toString(),
                          ).toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Total eAUD Unit Purchase</th>
                      <td>
                        <span className="q-badge">
                          {parseFloat(
                            statistics.totaleAUDUnitPurchase.toString(),
                          ).toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Total eAUD Unit Pending Sell</th>
                      <td>
                        <span className="q-badge">
                          {parseFloat(
                            statistics.totalEaudUnitPendingSell.toString(),
                          ).toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Total Land Unit Purchase</th>
                      <td>
                        <span className="q-badge">
                          {statistics.totalLandUnitPurchase}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Total Land Unit Pending Purchase</th>
                      <td>
                        <span className="q-badge">
                          {statistics.totalLandUnitPendingPurchase}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Total Land Unit Pending Sell</th>
                      <td>
                        <span className="q-badge">
                          {statistics.totalLandUnitPendingSell}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Total Project Value of Land</th>
                      <td>
                        <span className="q-badge">
                          {statistics.totalProjectLandValueInCents /
                            ONE_AUD_TO_CENTS}{' '}
                          AUD
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Recent Transactions</th>
                      <td>
                        <span className="q-badge">
                          {statistics.recentTransactionCount}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div> */}
          </div>
        )}
      </div>

      <a className="scroll-to-top" href="#page-top">
        <i className="fa fa-angle-up"></i>
      </a>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ready to Leave?
              </h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a className="btn btn-primary" href="login.html">
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
