import { revalidateNextJsPages } from "../api"
import { urlBasedOnEnv } from "./constants"

export const init_revalidate_next_pages = async () => {
  try {

    //@ts-ignore
    let res = await revalidateNextJsPages(`${urlBasedOnEnv[process.env.REACT_APP_NODE_ENV]}/api/revalidate?secret=${process.env.REACT_APP_ISR_TOKEN_NEXT}`)
    console.log('res:', res)
  } catch (e) {
    console.log('e:', e)


  }
}

export const capitalizedOneWord = (str: string) => {
  return str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase();
}
export const camelCaseToSpaced = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}
export const qtScrollIntoView = (id: string, cb?: () => void) => {
  let stScrollIntoViewTimeOUtId;
  clearTimeout(stScrollIntoViewTimeOUtId);

  stScrollIntoViewTimeOUtId = setTimeout(() => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      if (typeof cb === "function") {
        cb();
      }
    }
  }, 200);
};