import axios from 'axios'
import { ProjectColorSatus, SingleQuestionInterface } from '../utils/types'

export const voteQuestionSingle: SingleQuestionInterface = {
  questionTitle: '',
  answerKey: 'A',
  answerOptions: {
    A: '',
    B: '',
    C: '',
    D: '',
  },
}

export const testAxios = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URI}`,
  withCredentials: true,
})

export const projectStatusObject: {
  [prop: string]: {
    key: ProjectColorSatus
    color: string
    label: string
  }
} = {
  red: { key: 'red', label: 'Red', color: 'red' },
  yellow: { key: 'yellow', label: 'Yellow', color: 'yellow' },
  green: { key: 'green', label: 'Green', color: 'green' },
}

export const urlBasedOnEnv = {
  development: 'http://localhost:3001',
  dev: 'https://dev.user.quantum2.io',
  beta: 'https://beta.user.quantum2.io',
  prod: 'https://quantum2.io',
}

export const ONE_USD_TO_CENTS = 100
