/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  ONE_USD_TO_CENTS,
  OrderStatus,
  UnitDisplayNames,
  UnitDisplaySymbol,
  UserType,
} from '../../utils/types'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from 'reactstrap'
import { getuserSellOrderApi, postApproveSellOrder } from '../../api'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import {
  SortableTableHeader,
  TableTopFilters,
} from '../../component/misc/smallComponents'
import { UnitType } from '../../utils/types'
const Usersellorder = () => {
  const [state, setState] = useState<any>({
    sortDirection: 'desc',
    isExportDownloadLoading: false,
    dataList: [],
    filteredData: [],
    currentPage: 1,
    pageSize: 50,
    searchTerm: '',
    searchType: 'name',
    unitType: '',
    numberOfUnits: '',
    orderStatus: '',
    sortBy: '',
    fromDate: '',
    toDate: '',
    userType: '',
  })
  const [id, setId] = useState<any>('')

  const getData = async () => {
    const {
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      userType,
      unitType,
    } = state
    getuserSellOrderApi(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      unitType,
      userType,
    )
      .then((res) => {
        setState((prevState: any) => ({
          ...prevState,
          orders: res.data,
          filteredData: res.data,
        }))
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }

  const handleApprove = () => {
    const data = {
      orderId: id,
    }

    postApproveSellOrder(data)
      .then((res: any) => {
        toast(res.data.message)
        getData()
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }

  useEffect(() => {
    getData()
  }, [
    state.currentPage,
    state.fromDate,
    state.toDate,
    state.unitType,
    state.userType,
  ])
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  useEffect(() => {
    filterAndSortOrders()
  }, [
    state.numberOfUnits,
    state.orderStatus,
    state.startDate,
    state.endDate,
    state.sortBy,
    state.sortDirection,
    state.sortDirection,
    state.orders?.length,
  ])

  const filterAndSortOrders = () => {
    let filtered = state.orders

    if (state.orderStatus) {
      filtered = filtered.filter(
        (order: any) => order.orderStatus === state.orderStatus,
      )
    }

    if (state.sortBy) {
      filtered = filtered.sort((a: any, b: any) => {
        let comparison = 0

        if (state.sortBy === 'units') {
          const unitsA = a.currencyUnit?.numberOfUnits || 0
          const unitsB = b.currencyUnit?.numberOfUnits || 0
          comparison = unitsB - unitsA
        } else if (state.sortBy === 'amount') {
          const amountA = a.paymentDetails?.paymentAmount || 0
          const amountB = b.paymentDetails?.paymentAmount || 0
          comparison = amountB - amountA
        }

        return state.sortDirection === 'asc' ? -comparison : comparison
      })
    }

    setState((prevState: any) => ({ ...prevState, filteredData: filtered }))
  }
  const handleExportOrders = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        unitType,
        userType,
      } = state
      const response = await getuserSellOrderApi(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        unitType,
        userType,
        true,
      )

      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }
  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: 'desc',
      dataList: [],
      filteredData: [],
      searchTerm: '',
      searchType: 'name',
      unitType: '',
      numberOfUnits: '',
      orderStatus: '',
      startDate: null,
      endDate: null,
      sortBy: '',
    })
    getData()
  }

  const handleSortTableHead = (property: string) => {
    setState((prevState: any) => {
      const newSortDirection =
        property === prevState.sortBy
          ? prevState.sortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : prevState.sortDirection
      return { ...prevState, sortBy: property, sortDirection: newSortDirection }
    })
  }
  return (
    <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
      <div className="content-wrapper ">
        <div className="container-fluid">
          <ol
            className="breadcrumb"
            style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
          >
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">User Sell Orders</li>
          </ol>

          <div className="card mb-3">
            <div className="card-body">
              <div className="table-responsive">
                <TableTopFilters
                  state={state}
                  setState={setState}
                  getData={getData}
                  showDateRange={true}
                  handleExportOrders={handleExportOrders}
                />
                <table
                  className="table table-bordered text-nowrap text-center"
                  id="dataTable"
                  width="100%"
                  cellSpacing=""
                >
                  <thead className="">
                    <tr>
                      <th>S.No.</th>
                      <th>Name </th>
                      <th>Email</th>

                      <th>
                        <span className="d-block">Currency</span>

                        <select
                          className="w-100"
                          value={state.unitType}
                          onChange={(e) =>
                            setState({
                              ...state,
                              unitType: e.target.value,
                            })
                          }
                        >
                          <option value="">All</option>
                          {Object.values(UnitType).map((unit) => (
                            <option key={unit} value={unit}>
                              {UnitDisplayNames[unit]}
                            </option>
                          ))}
                        </select>
                      </th>

                      <SortableTableHeader
                        label="No. of Units"
                        property="units"
                        sortBy={state.sortBy}
                        sortDirection={state.sortDirection}
                        handleSortTableHead={handleSortTableHead}
                      />
                      <th>
                        <span className="d-block">Order Status</span>
                        <select
                          className="w-100"
                          onChange={(e) =>
                            setState({ ...state, orderStatus: e.target.value })
                          }
                        >
                          <option value="">All</option>
                          {Object.values(OrderStatus).map((status) => (
                            <option key={status} value={status}>
                              {status}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th>Transaction Fee (in Units)</th>

                      <SortableTableHeader
                        label="Expected Payout Amount"
                        property="amount"
                        sortBy={state.sortBy}
                        sortDirection={state.sortDirection}
                        handleSortTableHead={handleSortTableHead}
                      />
                      <th>
                        <span className="d-block">Account Type</span>

                        <select
                          className="w-100"
                          value={state.userType}
                          onChange={(e) =>
                            setState({
                              ...state,
                              userType: e.target.value,
                            })
                          }
                        >
                          <option value="">All</option>
                          <option value={UserType.USER}>USER</option>
                          <option value={UserType.BUSINESS}>BUSINESS</option>
                        </select>
                      </th>
                      <th>Approve Order</th>
                    </tr>
                  </thead>
                  {state?.filteredData &&
                    state.filteredData.map((item: any, index: number) => {
                      return (
                        <tr key={index} className="text-center">
                          <td>
                            <b>{index + 1}.</b>
                          </td>

                          <td>
                            {item.user?.firstName + ' ' + item.user?.lastName}
                          </td>
                          <td>{item.user?.email}</td>
                          <td>
                            {
                              UnitDisplayNames[
                                item.currencyUnit?.unitType as UnitType
                              ]
                            }
                          </td>
                          <td>{item.currencyUnit?.numberOfUnits}</td>
                          <td>{item.orderStatus}</td>
                          <td>
                            {item.currencyUnit?.sellOrderTransactionFeeInUnits}{' '}
                            {
                              UnitDisplayNames[
                                item.currencyUnit?.unitType as UnitType
                              ]
                            }
                          </td>
                          <td>
                            {item.paymentDetails?.paymentAmount /
                              ONE_USD_TO_CENTS}{' '}
                            {
                              UnitDisplaySymbol[
                                item.currencyUnit?.unitType as UnitType
                              ]
                            }
                          </td>
                          <td>{item.user?.userType}</td>
                          <td className="text-secondary">
                            <QButton
                              onClick={() => {
                                setId(item.orderId)
                                toggle()
                              }}
                              size="sm"
                            >
                              Approve
                            </QButton>
                          </td>
                        </tr>
                      )
                    })}
                </table>
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  centered
                  scrollable
                  onClosed={() => {}}
                >
                  <ModalHeader toggle={toggle}>
                    Approve User Sell Orders
                  </ModalHeader>
                  <ModalBody></ModalBody>
                  <ModalFooter>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={toggle}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleApprove()
                        toggle()
                      }}
                      data-dismiss="modal"
                    >
                      Approve
                    </button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  )
}

export default Usersellorder
